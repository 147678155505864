import { infoConsole } from "@/assets/js/helpers";
import mergeWith from "lodash.mergewith";
import { FileSubKeysTS } from "./fileManagerTS";

const extensionImages = import.meta.glob(
	"/src/assets/image/editor/extensions/*.svg",
	{ eager: true },
);

const FileTypeNames = {
	API: "API", // LEGACY
	EAP: "ExtendedAPI",
	LIB: "Library",
	WSS: "Websocket",
	APL: "ExtendedAPI Library", // Not used
	WSL: "Websocket Library", // Not used
	BIN: "Binary",
	TXT: "Text",
};

const MIMETypes = {
	"text/css": ".css",
	"text/html": ".html",
	"image/jpeg": ".jpeg",
	"text/javascript": ".js",
	"application/json": ".json",
	"audio/mpeg": ".mp3",
	"video/mp4": ".mp4",
	"video/mpeg": ".mp4", // its not correct but it will result in good icon being displayed
	"image/png": ".png",
	"application/pdf": ".pdf",
	"image/svg+xml": ".svg",
	"application/x-tar": ".tar",
	"text/plain": ".txt",
	"application/zip": ".zip",
	"application/xml": ".xml",
	"application/octet-stream": ".bin",
};

const monacoLangMap = {
	javascript: "typescript",
	groovy: "groovy",
	java: "java",
	php: "php",
};

const monacoExtensionMap = {
	js: "typescript",
	rb: "ruby",
	vue: "html",
	ts: "typescript",
	tsx: "typescript",
	svelte: "typescript",
	md: "markdown",
};

const FileTypesDataObject = ["API", "EAP", "WSS"];

class MarsFileMeta {
	// WIP [0] Change more logic to class instance
	constructor(fileObj, parentPathChunk = "") {
		if (fileObj?.constructor === this.constructor) {
			console.error("SAME CONSTRUCTOR");
			return;
		}

		const warnInvalidFile = (fl) => {
			if (fl) {
				if (fl.id && !fl.type) {
					console.warn("Invalid file used TYPE", fl);
				} else if (!fl.fullPath && !fl.name) {
					console.warn("Invalid file used FULLPATH", fl);
				}
			} else {
				console.warn("No FL", fl);
			}
		};

		warnInvalidFile(fileObj);

		// Name is optional, path is mandatory
		const name =
			fileObj.name || this.getFileNameFromPath(fileObj.fullPath) || "";
		const fullPath =
			fileObj.fullPath || this.parseFullPathValue(parentPathChunk, name) || "";
		this.id = fileObj.id || 0;
		this.oldId = fileObj.oldId || fileObj.id || null;
		this.name = name;
		this.fullPath = fullPath;
		this.type = fileObj.type || "TXT";
		this.children = this.setupChildren(fileObj.children, fullPath);
		this.createdAt = fileObj.created || "";
		this.errors = fileObj.errors || 0;
		this.options = this.getParsedOptions(fileObj.options);
		this.originalFileObjDebug = fileObj;

		if (this.id === -1) {
			console.warn("Invalid file id", fileObj);
		}
	}

	get validKeys() {
		return [
			"id",
			"oldId",
			"name",
			"fullPath",
			"type",
			"children",
			"created",
			"createdAt",
			"errors",
			"options",
		];
	}

	getOptions(isStringified = false) {
		// DB supports Object
		if (isStringified) {
			return typeof this.options === "string"
				? this.options
				: JSON.stringify(this.options);
		}
		return this.options;
	}

	getParsedOptions(options = {}) {
		if (typeof options === "string") {
			try {
				return JSON.parse(options);
			} catch {
				return {};
			}
		} else {
			return { ...options };
		}
	}

	setValidOptionsLanguage() {
		if (this.type && this.type !== "TXT" && !this.options.language) {
			infoConsole(`🟦 Setting default language for: ${this.type}`, "warn");
			this.options.language = "JavaScript";
		}
	}

	getFileExtension() {
		const splitted = this.name.split(".");
		if (splitted.length > 1) {
			return splitted[splitted.length - 1];
		}
		return "";
	}

	getFileNameFromPath(path = this.fullPath) {
		if (!path) return null;
		const splitted = path.split("/");
		if (splitted?.length > 1) {
			return splitted[splitted.length - 1];
		}
		return path;
	}

	parseFullPathValue(parentPathChunk = "", name) {
		if (!name) {
			console.error("Error generating file path", parentPathChunk, name);
			return "";
		}
		return parentPathChunk ? `${parentPathChunk}/${name}` : name;
	}

	getFile(isStringOpt = false) {
		this.setValidOptionsLanguage();

		const output = {
			id: this.id,
			oldId: this.oldId || this.id,
			name: this.name,
			type: this.type,
			options: this.getOptions(isStringOpt),
			fullPath: this.fullPath, // Not used for DB
			created: this.createdAt, // Not used for DB
			errors: this.errors, // Not used for DB
			children: this.children || null,
		};

		return output;
	}

	getDbFile(isStringOpt = false) {
		this.setValidOptionsLanguage();

		const output = {
			id: this.id,
			path: this.fullPath,
			type: this.type,
			options: this.getOptions(isStringOpt),
		};

		if (this.children?.length) {
			// Remove in future when flat tree
			output.children = this.children || [];
		}

		return output;
	}

	setupChildren(childrenArr, parentName, isInitialSetup = true) {
		// Remove in future when flat tree [Whole children key]
		return (
			(childrenArr?.length &&
				childrenArr.map((children) => {
					return children instanceof MarsFileMeta
						? children
						: new MarsFileMeta(children, parentName);
				})) ||
			[]
		);
	}

	isMarsFileFolder() {
		return this.id === 0 || this.type === "FLD";
	}

	mergeMarsFile(otherFileObj = {}, parentPathChunk = "") {
		// console.log(">> ", otherFileObj);

		const getNumber = (...nums) => {
			const found = nums.find((num) => {
				const parsed = parseInt(num, 10);
				return !isNaN(parsed);
			});
			return found;
		};

		for (const key of this.validKeys) {
			if (key in Object.keys(otherFileObj)) {
				console.log("Unknown keys detected", key);
			}
		}

		const setupNameAndFullPath = () => {
			if (otherFileObj.name) {
				this.name = otherFileObj.name;

				if (parentPathChunk) {
					this.fullPath = this.parseFullPathValue(
						parentPathChunk,
						otherFileObj.name,
					);
				} else {
					if (!this.fullPath.includes(this.name)) {
						// If differs from current name
						this.fullPath = this.name;
					}
				}
			}
			if (otherFileObj.fullPath) {
				const path = otherFileObj.fullPath || this.fullPath;
				this.fullPath = path;

				if (!otherFileObj.name) {
					this.name =
						this.getFileNameFromPath(path) || otherFileObj.name || this.name;
				}
			}

			return { name: this.name, fullPath: this.fullPath };
		};

		const { name, fullPath } = setupNameAndFullPath();
		this.id = getNumber(otherFileObj.id, this.id);
		this.oldId = getNumber(otherFileObj.oldId, otherFileObj.id, this.oldId);
		this.type = otherFileObj.type || this.type;
		if (otherFileObj.children) {
			this.children = this.setupChildren(
				otherFileObj.children,
				fullPath,
				false,
			);
		}
		this.createdAt = otherFileObj.created || this.createdAt || "";
		this.errors = getNumber(otherFileObj.errors, this.errors, 0);
		this.mergeOptions(otherFileObj.options || this.options);

		this.originalFileObjDebug = otherFileObj;
		return this;
	}

	mergeOptions(options = {}) {
		const customizer = (objValue, srcValue) => {
			if (Array.isArray(objValue)) {
				return srcValue;
			}
		};

		const mergedOptions = mergeWith(
			this.options,
			this.getParsedOptions(options),
			customizer,
		);
		this.options = mergedOptions;
	}
}

class MarsFileData {
	constructor(dataFile) {
		this.setParsedData(dataFile);
	}

	getDataString() {
		return JSON.stringify(this.data);
	}

	getDataParsed(dataFile = "") {
		try {
			return JSON.parse(dataFile);
		} catch {
			return dataFile;
		}
	}

	getNonEmptyData(isStringified = false) {
		// Not used yet.
		if (typeof this.data === "string") {
			console.warn("Not supported file format", this.data.slice(0, 15));
			return null;
		}

		const tempData = {};
		for (const [mK, mV] of Object.entries(this.data)) {
			// Filter out empty values
			if (mV) {
				tempData[mK] = mV;
			}
		}
		if (isStringified) {
			return JSON.stringify(tempData);
		}
		return tempData;
	}

	setParsedData(dataFile = "") {
		this.data =
			typeof dataFile === "string" ? this.getDataParsed(dataFile) : dataFile;
	}
}

function getSVGFromFileContentType(contentType) {
	const ext = MIMETypes[contentType] || ".bin";
	return parseImageExtension({ name: ext });
}

/**
 * Parsers and returns language used for editor and file tree.
 * @param {object} item - file tree object.
 */
function getMonacoLang(itemObj = {}) {
	const getInstance = (item) => {
		return item instanceof MarsFileMeta ? item : new MarsFileMeta(item);
	};

	const marsFileMeta = getInstance(itemObj);
	const item = marsFileMeta.getFile();

	const parseLanguageFromExtension = () => {
		const ext = marsFileMeta.getFileExtension();
		return ext in monacoExtensionMap ? monacoExtensionMap[ext] : ext || "text";
	};

	const parseLanguageFromTypeOptions = (name = "javascript", type, options) => {
		const getApiWSLanguage = (type, options) => {
			// File Type checks are OK here
			if (typeof options === "string") {
				try {
					const parsedLang = JSON.parse(options).language;
					return parsedLang;
				} catch (err) {
					console.warn(
						`🐛 Type [${type}], failed parsing [Using JS Fallback] >>`,
						err.message,
					);
					return "javascript";
				}
			} else if (!options?.language) {
				console.log(`🐛 Type [${type}], no lang [Using JS Fallback]`);
			}
			return options?.language || "javascript";
		};

		// Parses API (EAP) and WS lang
		const apiLang = getApiWSLanguage(type, options);
		if (apiLang) {
			return monacoLangMap[apiLang.toLowerCase()];
		} else {
			// Fallback if invalid opt type
			console.log("[WARN] Using extension fallback lang");
			const parsedExt = parseLanguageFromExtension(name);
			return parsedExt || "text";
		}
	};

	// Has to have {type, name, options..}
	const scriptTypes = ["API", "EAP", "WSS", "APL", "WSL", "LIB"];
	if (item.type && scriptTypes.includes(item.type)) {
		return parseLanguageFromTypeOptions(item.name, item.type, item.options);
	}
	return item.name ? parseLanguageFromExtension(item.name) : "text";
}

function getSvgEditorImage(svgName) {
	try {
		return extensionImages[`/src/assets/image/editor/extensions/${svgName}.svg`]
			.default;
	} catch (err) {
		console.warn(err.message, svgName);
		return null;
	}
}

function parseImageExtension(item, isFolder = false, isFolderOpen = false) {
	const splitName = item.name?.split(".");
	const fileExt = splitName?.[splitName.length - 1];

	if (["API", "EAP"].includes(item.type)) {
		return getSvgEditorImage("api");
	} else if (["WSS"].includes(item.type)) {
		return getSvgEditorImage("wss");
	} else if (["APL", "WSL", "LIB"].includes(item.type)) {
		return getSvgEditorImage("lib");
	}

	if (isFolder && !isFolderOpen) {
		return getSvgEditorImage("folder");
	}
	if (isFolder && isFolderOpen) {
		return getSvgEditorImage("open-folder");
	} else if (["html"].includes(fileExt)) {
		return getSvgEditorImage("html");
	} else if (["css"].includes(fileExt)) {
		return getSvgEditorImage("css");
	} else if (["svg"].includes(fileExt)) {
		return getSvgEditorImage("svg");
	} else if (["js"].includes(fileExt)) {
		return getSvgEditorImage("js");
	} else if (["vue"].includes(fileExt)) {
		return getSvgEditorImage("vuejs");
	} else if (["img", "png", "bmp", "jpeg", "jpg"].includes(fileExt)) {
		return getSvgEditorImage("image");
	} else if (["wav", "mp3", "flac", "ogg"].includes(fileExt)) {
		return getSvgEditorImage("audio");
	} else if (["mp4", "h264", "avi"].includes(fileExt)) {
		return getSvgEditorImage("video");
	} else if (["txt"].includes(fileExt)) {
		return getSvgEditorImage("txt");
	} else if (["pdf"].includes(fileExt)) {
		return getSvgEditorImage("pdf");
	} else if (["wss"].includes(fileExt)) {
		return getSvgEditorImage("wss");
	} else if (["php"].includes(fileExt)) {
		return getSvgEditorImage("php");
	} else if (["ts"].includes(fileExt)) {
		return getSvgEditorImage("ts");
	} else if (["csharp"].includes(fileExt)) {
		return getSvgEditorImage("csharp");
	} else if (["sql"].includes(fileExt)) {
		return getSvgEditorImage("sql");
	} else if (["bin"].includes(fileExt)) {
		return getSvgEditorImage("bits");
	}

	if (["TXT"].includes(item.type)) {
		return getSvgEditorImage("txt");
	}

	// return getSvgEditorImage('bits.svg?data')
	return getSvgEditorImage("bits");
}

function setSvgHue(item, fallbackLang) {
	const hueColors = {
		javascript: {
			opacity: "0.9",
			filter: "hue-rotate(90deg) saturate(0) brightness(1.5)",
		},
		php: { filter: "hue-rotate(158deg) saturate(1.1)" },
		groovy: { filter: "hue-rotate(72deg) saturate(1.6)" },
		ruby: { filter: "hue-rotate(222deg) saturate(2.0)" },
	};

	if (["API", "EAP"].includes(item.type)) {
		// #39BC6B JS
		// #9C44D3 PHP
		// #52A6D3 Groovy
		// #E34040 Ruby
		const parseLang = (options) => {
			return options?.language.toLowerCase();
		};

		if (item.options?.language) {
			const lowerLang = parseLang(item.options);
			if (lowerLang) {
				return hueColors[lowerLang];
			}
		} else {
			try {
				const parsedOptions = item.options && JSON.parse(item.options);
				const lowerLang = parseLang(parsedOptions);
				if (lowerLang) {
					return hueColors[lowerLang];
				}
			} catch {
				// ignore wrongly parsed options [Made from older version of mars]
			}
		}

		if (fallbackLang) {
			return hueColors[fallbackLang];
		}
		// } else if (["APL", "WSL", "LIB"].includes(item.type)) {
		//   // Libraries [Yellow]
		//   return { filter: "hue-rotate(284deg) saturate(2.0)" };
	}

	return "";
}

export default {
	MarsFileMeta,
	MarsFileData,
	FileTypesDataObject,
	FileType: FileTypeNames,
	getMonacoLang,
	getSvgEditorImage,
	parseImageExtension,
	getSVGFromFileContentType,
	setSvgHue,
};
